import actions from './actions';
import { ADMIN_ROLE } from 'utils/Constant';
const initState = {
    authToken: null,
    modulePermission: JSON.parse(localStorage.getItem('modulePermission')),
    adminType: localStorage.getItem('adminType') || ADMIN_ROLE?.SUPER_ADMIN
};

export default function authReducer(state = initState, action) {
    switch (action.type) {
        case actions.LOGIN_SUCCESS:
            return {
                ...state,
                authToken: action?.token?.accessToken,
                modulePermission: action?.token?.permissions,
                adminType: action?.token?.adminType?.toUpperCase()
            };
        case actions.LOGOUT:
            return initState;
        case actions.MODULE_PERMISSION:
            return {
                ...state,
                modulePermission: action?.permissions
            };
        default:
            return state;
    }
}
