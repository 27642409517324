export const API_URL = {
    SIGN_IN: 'cms/login',
    USER_LIST: 'cms/user/list',
    KEYWORD_LIST: 'cms/keywords-list',
    MANIPULATE_KEYWORDS: 'cms/keywords',
    KEYWORDS_CLONE: 'cms/keywords/clone',
    QUESTIONNAIRE_LIST: 'cms/questionnaire-question',
    KEYWORD_DETAILS: 'cms/keywords-details',
    QUESTIONNAIRE_DETAILS: 'cms/questionnaire-question-details',
    ADD_QUESTION_KEYWORD: 'cms/questionnaire-question/keywords',
    FETCH_WELLNESS_CATEGORY: 'cms/wellness-category/list',
    WELLNESS_CATEGORY_BY_ID: 'cms/wellness-category',
    WELLNESS_CATEGORY_CLONE: 'cms/wellness-category/clone',
    FETCH_SUB_CATEGORY: 'cms/wellness-sub-category',
    FETCH_SUB_CATEGORY_TAGS: 'cms/wellness-category/tags',
    GET_ACCESS_LEVEL_IDs: 'cms/access-levels',
    GET_ACCESS_LEVEL_LIST: 'cms/access-levels/list',
    TAG_LIST: 'cms/wellness-category/tags',
    FETCH_FEEDBACKS_TAGS: 'cms/wellness-category/feedback-tags',
    GET_TARGETS: 'cms/target',
    GET_CURRENCY: 'cms/currency',
    GET_PRODUCT_CATEGORY_LIST: 'cms/product-category/list',
    TIER2_KEYWORD_DETAILS: 'cms/tier2-keywords-details',
    SUBCATEGORIES_BASED_ON_CATEGORIES: 'cms/wellness-category/sub-category',
    CONTENT: 'cms/content',
    CONTENT_LIST: 'cms/content-list',
    CONTENT_CLONE: 'cms/content/clone',
    PROGRAM_LIST: 'cms/program-list',
    PROGRAMS: 'cms/program',
    PROGRAM_CLONE: 'cms/program/clone',
    USER_DELETE: 'cms/user',
    SOUND_LIST: 'cms/sound/list',
    SOUND: 'cms/sound',
    SOUND_CLONE: 'cms/sound/clone',
    BACKGROUND_IMAGE_LIST: 'cms/background-image/list',
    BACKGROUND_IMAGE: 'cms/background-image',
    BACKGROUND_IMAGE_CLONE: 'cms/background-image/clone',
    ADMIN: 'cms/sub-admin/modules/permissions',
    ADMIN_PERMISSIONS_LIST: 'cms/sub-admin/modules/permissions/list',
    DELETE_ADMIN: 'cms/sub-admin/modules/permissions',
    DASHBOARD_AGE_RANGE_GRAPH: 'cms/dashboard-users/age-range/graph',
    DASHBOARD_GENDER: 'cms/dashboard-users/gender/graph',
    DASHBOARD_REGION: 'cms/dashboard-users/region/graph',
    DASHBOARD_NEW_REGISTERED_USERS: 'cms/dashboard-users/new-registered/graph',
    DASHBOARD_HEAR_ABOUT_US: 'cms/dashboard-users/hear-about-us/graph',
    DASHBOARD_USER_ACTIVE_COUNT: 'cms/dashboard-users',
    INTEREST_LIST: 'cms/interests-list',
    INTEREST: 'cms/interests',
    INTEREST_CLONE: 'cms/interests/clone',
    HEAR_ABOUT_US: 'cms/hear-about-us',
    HEAR_ABOUT_US_LIST: 'cms/hear-about-us/list',
    HEAR_ABOUT_US_CLONE: 'cms/hear-about-us/clone',
    TAG_CMS_LIST: 'cms/tag-list',
    TAG: 'cms/tag',
    TAG_CLONE: 'cms/tag/clone',
    CONTENT_BOX_LIST: 'cms/content-box-list',
    CONTENT_BOX_LIST_CATEGORY: 'cms/content-box-list/category',
    FETCH_FEEDBACKS_TAGS: 'cms/wellness-category/feedback-tags',
    CONTENT_BOX: 'cms/content/box',
    CONTENT_BOX_CLONE: 'cms/content/box/clone',
    LANGUAGE: 'cms/languages',
    COUNTRY: 'cms/country/region',
    REGION: 'cms/regions',
    REGION_LIST: 'cms/regions/list',
    BANNED: 'user/ban',
    COUNTRY_LIST: 'cms/country/list',
    MANIPULATE_COUNTRY: 'cms/country',
    CURRENCY: 'cms/currency',
    CURRENCY_COUNTRIES: 'cms/currency-countries',
    STATIC_PAGES: 'cms/static-pages',
    STATIC_PAGES_LIST: 'cms/static-pages/list',
    FEEDBACK_LIST: 'cms/user-feedback/list',
    FEEDBACK: 'cms/user-feedback',
    CURRENCY_LIST: 'cms/currency/list',
    DAILY_WISDOM_LIST: 'cms/daily-wisdom/list',
    DAILY_WISDOM: 'cms/daily-wisdom',
    DAILY_WISDOM_CLONE: 'cms/daily-wisdom/clone',
    SALES_PAGE_LIST: 'cms/sales-page/list',
    SALES_PAGE: 'cms/sales-page',
    SALES_PAGE_CLONE: 'cms/sales-page/clone',
    ALL_LANGUAGES_LIST: 'cms/all-languages/list',
    ALL_LANGUAGES: 'cms/all-languages',
    PROGRAM_CONTENT_LIST: 'cms/program/content/list',
    EMAIL_TEMPLATE_LIST: 'cms/email-template/list',
    EMAIL_TEMPLATE: 'cms/email-template',
    NOTIFICATION_LIST: 'cms/notification/list',
    NOTIFICATION: 'cms/notification',
    REFERRED_LIST: 'cms/user/referred/list',
    ACCESS_CONTROL: 'cms/user-module-permission/list',
    ACCESS_CONTROL_EDIT: 'cms/user-module-permission',
    USER_LIST_EXPORT: 'cms/user/list/export',
    FORGOT_PASSWORD: 'cms/forgot-password',
    CHANGE_PASSWORD: 'cms/change-password',
    RESET_PASSWORD: 'cms/reset-password',
    RESEND_LINK: 'cms/resend-verification-link',
    REASONS_LIST: 'cms/report/reason/list',
    REASONS: 'cms/report/reason',
    CONSTANTS_LIST: 'cms/constants/list',
    CONSTANTS: 'cms/constants',
    PRODUCT_CATEGORIES_LIST: 'cms/product-category/list',
    PRODUCT_CATEGORIES: 'cms/product-category',
    CLONE_PRODUCT_CATEGORY: 'cms/product-category/clone',
    REFERRALS_PRODUCT_LIST: 'cms/referrals/product/list',
    REFERRALS_PRODUCT: 'cms/referrals/product',
    REFERRALS_CLONE: 'cms/referrals/product/clone'
};

export const PAGINATION = { PER_PAGE: 10 };

export const MODAL_TYPE = {
    ADD: 'ADD',
    EDIT: 'EDIT'
};

export const SITE_ADMIN_MODULE_PERMISSION = [
    {
        moduleName: 'DASHBOARD',
        title: 'Dashboard',
        isActive: false,
        canAddEdit: false,
        canDelete: false
    },
    {
        moduleName: 'ADMIN',
        title: 'Admin',
        isActive: false,
        canAddEdit: false,
        canDelete: false
    },
    {
        moduleName: 'USERS',
        title: 'Total Users',
        isActive: false,
        canAddEdit: false,
        canDelete: false
    },
    {
        moduleName: 'LOGGED-IN',
        title: 'LoggedIn Users',
        isActive: false,
        canAddEdit: false,
        canDelete: false
    },
    {
        moduleName: 'FILLED-QUESTIONNAIRE',
        title: 'Users Who Filled Questionnaire',
        isActive: false,
        canAddEdit: false,
        canDelete: false
    },
    {
        moduleName: 'DID-NOT-FILLED-QUESTIONNAIRE',
        title: 'Users Who Did Not Filled Questionnaire',
        isActive: false,
        canAddEdit: false,
        canDelete: false
    },
    {
        moduleName: 'REFERRED-USERS',
        title: 'Referred Users',
        isActive: false,
        canAddEdit: false,
        canDelete: false
    },
    {
        moduleName: 'QUESTIONS',
        title: 'Questions',
        isActive: false,
        canAddEdit: false,
        canDelete: false
    },
    {
        moduleName: 'KEYWORDS',
        title: 'Keywords',
        isActive: false,
        canAddEdit: false,
        canDelete: false
    },
    {
        moduleName: 'INTEREST',
        title: 'Interest',
        isActive: false,
        canAddEdit: false,
        canDelete: false
    },
    {
        moduleName: 'TAG',
        title: 'Tag',
        isActive: false,
        canAddEdit: false,
        canDelete: false
    },
    {
        moduleName: 'LANGUAGE',
        title: 'Language',
        isActive: false,
        canAddEdit: false,
        canDelete: false
    },
    {
        moduleName: 'REGION',
        title: 'Region',
        isActive: false,
        canAddEdit: false,
        canDelete: false
    },
    {
        moduleName: 'COUNTRY',
        title: 'Country',
        isActive: false,
        canAddEdit: false,
        canDelete: false
    },
    {
        moduleName: 'CONTENT',
        title: 'Content',
        isActive: false,
        canAddEdit: false,
        canDelete: false
    },
    {
        moduleName: 'PROGRAMS',
        title: 'Programs',
        isActive: false,
        canAddEdit: false,
        canDelete: false
    },
    {
        moduleName: 'WELLNESS-CATEGORY',
        title: 'Wellness Category',
        isActive: false,
        canAddEdit: false,
        canDelete: false
    },
    {
        moduleName: 'SOUND',
        title: 'Sound',
        isActive: false,
        canAddEdit: false,
        canDelete: false
    },
    {
        moduleName: 'BACKGROUND-IMAGES',
        title: 'Background images',
        isActive: false,
        canAddEdit: false,
        canDelete: false
    },

    {
        moduleName: 'SUBSCRIPTION',
        title: 'Subscription Level',
        isActive: false,
        canAddEdit: false,
        canDelete: false
    },
    {
        moduleName: 'ACCESS-CONTROL',
        title: 'Access Control',
        isActive: false,
        canAddEdit: false,
        canDelete: false
    },
    {
        moduleName: 'PRODUCT-CATEGORIES',
        title: 'Product Categories',
        isActive: false,
        canAddEdit: false,
        canDelete: false
    },
    {
        moduleName: 'PRODUCTS',
        title: 'Products',
        isActive: false,
        canAddEdit: false,
        canDelete: false
    },
    {
        moduleName: 'DAILY-WISDOM',
        title: 'Daily Wisdom',
        isActive: false,
        canAddEdit: false,
        canDelete: false
    },
    {
        moduleName: 'SALES-PAGE',
        title: 'Sales Page',
        isActive: false,
        canAddEdit: false,
        canDelete: false
    },
    // {
    //     moduleName: 'CURRENCY',
    //     title: 'Currency',
    //     isActive: false,
    //     canAddEdit: false,
    //     canDelete: false
    // },
    {
        moduleName: 'STATIC-PAGES',
        title: 'Static Pages',
        isActive: false,
        canAddEdit: false,
        canDelete: false
    },
    {
        moduleName: 'EMAIL',
        title: 'Email',
        isActive: false,
        canAddEdit: false,
        canDelete: false
    },
    {
        moduleName: 'NOTIFICATIONS',
        title: 'Notification',
        isActive: false,
        canAddEdit: false,
        canDelete: false
    },
    {
        moduleName: 'FEEDBACK',
        title: 'Feedback',
        isActive: false,
        canAddEdit: false,
        canDelete: false
    },
    {
        moduleName: 'CHANGE-PASSWORD',
        title: 'Change Password',
        isActive: false,
        canAddEdit: false,
        canDelete: false
    },
    {
        moduleName: 'CONSTANTS',
        title: 'Constants',
        isActive: false,
        canAddEdit: false,
        canDelete: false
    }

    // {
    //     moduleName: 'REPORT-REASONS',
    //     title: 'Report Reasons',
    //     isActive: false,
    //     canAddEdit: false,
    //     canDelete: false
    // }
];

export const ADMIN_TYPE = [
    {
        key: 'SUPER_ADMIN',
        title: 'Super Admin'
    },
    {
        key: 'SUB_ADMIN',
        title: 'Sub Admin'
    }
];

export const ADMIN_ROLE = {
    SUPER_ADMIN: 'SUPER_ADMIN',
    SUB_ADMIN: 'SUB_ADMIN'
};

export const HEART_TYPE = [
    {
        key: 'NONE',
        title: 'NONE'
    },
    {
        key: 'SILVER',
        title: 'SILVER'
    },
    {
        key: 'GOLDEN',
        title: 'GOLDEN'
    },
    {
        key: 'DIAMOND',
        title: 'DIAMOND'
    }
];

export const BACKGROUND_IMAGE_TYPE = [
    // {
    //     key: 'ALL',
    //     title: 'ALL'
    // },
    // {
    //     key: 'MEDITATION_ROOM',
    //     title: 'MEDITATION ROOM'
    // },
    {
        key: 'MEDITATION_TIMER',
        title: 'Meditation Timer'
    }
    // {
    //     key: 'SESSION',
    //     title: 'SESSION'
    // }
];

export const LEVELS_COLORS = [
    'lime',
    'gold',
    'magenta',
    'orange',
    'volcano',
    'cyan',
    'blue',
    'geekblue',
    'purple'
];

export const COLOR_BY_LEVEL = {
    'Level 0': 'lime',
    'Level 1': 'gold',
    'Level 2': 'magenta',
    'Level 3': 'orange',
    'Level 4': 'volcano',
    'Level 5': 'cyan',
    'Level 6': 'blue',
    'Level 6': 'geekblue',
    'Level 7': 'purple'
};

export const SOUND_TYPE = {
    AMBIENT_SOUND: 'Ambient Sound',
    START_SOUND: 'Start/End Sound'
};

export const ALPHABET_REGEX = /^[a-zA-Z ]+$/;

export const NUMBER_REGEX = /^[0-9]+$/;

export const FILTER_BY_YES_OR_NO = [
    {
        text: 'Yes',
        value: 'true'
    },
    {
        text: 'No',
        value: 'false'
    }
];
export const FILTER_BY_ACTIVE_OR_INACTIVE = [
    {
        text: 'Active',
        value: 'true'
    },
    {
        text: 'Inactive',
        value: 'false'
    }
];

export const FILTER_BY_SOUND_TYPE = [
    {
        text: 'Ambient Sound',
        value: 'AMBIENT_SOUND'
    },
    {
        text: 'Start Sound',
        value: 'START_SOUND'
    }
];

export const formInputRules = [
    {
        required: true,
        message: ''
    },
    {
        validator: (rules, value, callback) => {
            if (!value?.trim()) return callback('Please enter something');
            callback();
        }
    }
];

export const NOTIFICATION_TYPE = {
    DailyWisdom: 'Daily Wisdom',
    Programs: 'Programs',
    Subscription: 'Subscription',
    Meditation: 'Meditation'
};

export const NOTIFICATION_TYPE_API_VALUE = {
    'Daily Wisdom': 'DailyWisdom',
    Programs: 'Programs',
    Subscription: 'Subscription',
    Meditation: 'Meditation'
};

export const CONTENT_ERROR_MESSAGE = {
    InitialExperience: 'Is For Initial Experience',
    BeginExperience: 'Is For Begin Experience',
    BirthdayContent: 'Birthday Content'
};

export const CONTENT_ERROR = {
    InitialExperience: 'isForInitialExperience',
    BeginExperience: 'isForBeginExperience',
    BirthdayContent: 'isForBirthdayContent'
};

export const USERS_PATH = [
    {
        pathname: '/filled-questionnaire',
        value: 'UserFilledQuestionnaire',
        header: 'Users who filled the questionnaire'
    },
    {
        pathname: '/did-not-filled-questionnaire',
        value: 'UserUnFilledQuestionnaire',
        header: 'Users who did not filled the questionnaire'
    },
    {
        pathname: '/logged-in',
        value: 'CurrentlyLoggedInUser',
        header: 'Currently logged in users'
    }
];
