import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';
import { connect } from 'react-redux';

import App from './containers/App/App';
import asyncComponent from './helpers/AsyncFunc';
import { flatMap } from 'lodash';

const RestrictedRoute = ({ component: Component, isLoggedIn, ...rest }) => (
    <Route
        {...rest}
        render={(props) =>
            isLoggedIn ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: '/signin',
                        state: { from: props.location }
                    }}
                />
            )
        }
    />
);
const PublicRoutes = ({ history, isLoggedIn }) => {
    return (
        <ConnectedRouter history={history}>
            <Switch>
                <Route
                    exact
                    path={'/'}
                    component={asyncComponent(() => import('./containers/Page/signin'))}
                />
                <Route
                    exact
                    path={'/signin'}
                    component={asyncComponent(() => import('./containers/Page/signin'))}
                />
                <Route
                    exact
                    path={'/forgot-password'}
                    component={asyncComponent(() => import('./containers/Page/forgot-password'))}
                />
                <Route
                    exact={false}
                    path={'/reset-password'}
                    component={asyncComponent(() => import('./containers/Page/reset-password'))}
                />

                <RestrictedRoute path="/" component={App} isLoggedIn={isLoggedIn} />
            </Switch>
        </ConnectedRouter>
    );
};

export default connect((state) => ({
    isLoggedIn: state.Auth.authToken !== null
}))(PublicRoutes);
