import { SearchOutlined } from '@ant-design/icons';
import { Input, Tag } from 'antd';
import Button from 'components/uielements/button';
import { Map } from 'immutable';
import Highlighter from 'react-highlight-words';
import { get, isEqual } from 'lodash';
import React from 'react';
import { Content } from 'antd/lib/layout/layout';

export function clearToken() {
    localStorage.removeItem('authToken');
}

export function getToken() {
    try {
        const idToken = localStorage.getItem('authToken');
        return new Map({ idToken });
    } catch (err) {
        clearToken();
        return new Map();
    }
}

export function timeDifference(givenTime) {
    givenTime = new Date(givenTime);
    const milliseconds = new Date().getTime() - givenTime.getTime();
    const numberEnding = (number) => {
        return number > 1 ? 's' : '';
    };
    const number = (num) => (num > 9 ? '' + num : '0' + num);
    const getTime = () => {
        let temp = Math.floor(milliseconds / 1000);
        const years = Math.floor(temp / 31536000);
        if (years) {
            const month = number(givenTime.getUTCMonth() + 1);
            const day = number(givenTime.getUTCDate());
            const year = givenTime.getUTCFullYear() % 100;
            return `${day}-${month}-${year}`;
        }
        const days = Math.floor((temp %= 31536000) / 86400);
        if (days) {
            if (days < 28) {
                return days + ' day' + numberEnding(days);
            } else {
                const months = [
                    'Jan',
                    'Feb',
                    'Mar',
                    'Apr',
                    'May',
                    'Jun',
                    'Jul',
                    'Aug',
                    'Sep',
                    'Oct',
                    'Nov',
                    'Dec'
                ];
                const month = months[givenTime.getUTCMonth()];
                const day = number(givenTime.getUTCDate());
                return `${day} ${month}`;
            }
        }
        const hours = Math.floor((temp %= 86400) / 3600);
        if (hours) {
            return `${hours} hour${numberEnding(hours)} ago`;
        }
        const minutes = Math.floor((temp %= 3600) / 60);
        if (minutes) {
            return `${minutes} minute${numberEnding(minutes)} ago`;
        }
        return 'a few seconds ago';
    };
    return getTime();
}

export const getColumnSearchProps = (
    dataIndex,
    displayName,
    displayChips = false,
    tagKeyName = 'name',
    key = 'id',
    keyValue = 'name',
    isSelectedOnly = false
) => {
    var searchInput = null,
        searchText = '';

    return {
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8, backgroundColor: '#f1f3f6' }}>
                <Input
                    ref={(node) => {
                        searchInput = node;
                    }}
                    placeholder={`Search`}
                    // placeholder={`Search ${displayName}`}
                    value={selectedKeys[0]}
                    onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : []);
                    }}
                    onPressEnter={(e) => {
                        e.stopPropagation();
                        searchText = selectedKeys[0];
                        confirm();
                    }}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => {
                        searchText = selectedKeys[0];
                        confirm();
                    }}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}>
                    Search
                </Button>
                <Button
                    onClick={() => {
                        clearFilters();
                        confirm();
                        searchText = '';
                    }}
                    size="small"
                    style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined type="search" style={{ color: filtered ? '#B08C3D' : undefined }} />
        ),
        onFilter: (value, record) => {
            return get(record, dataIndex).toString().toLowerCase().includes(value.toLowerCase());
        },
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.select());
            }
        },
        render: (text, record) => {
            let filteredArray = record?.[tagKeyName];
            if (isSelectedOnly) filteredArray = filteredArray?.filter((item) => item?.isSelected);
            return isEqual(searchText, dataIndex) ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : displayChips ? (
                <Content style={{ display: 'flex', flexDirection: 'column' }}>
                    {text}
                    <Content>
                        {filteredArray?.map((item) => (
                            <Tag
                                key={item?.[key]}
                                color="orange"
                                style={{ maxWidth: 'max-content', margin: '3px' }}>
                                {item?.[keyValue]}
                            </Tag>
                        ))}
                    </Content>
                </Content>
            ) : (
                text
            );
        }
    };
};

export const bytesToSize = (bytes, decimals = 2) => {
    if (bytes === 0) return '0';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    // const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm));
};
