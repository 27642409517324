const actions = {
    CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
    LOGOUT: 'LOGOUT',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_ERROR: 'LOGIN_ERROR',
    MODULE_PERMISSION: 'MODULE_PERMISSION',

    checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
    login: (data) => {
        localStorage.setItem('authToken', data?.token?.accessToken);
        localStorage.setItem('isLoggedInCMS', true);
        localStorage.setItem('isSuperAdmin', data?.token?.isSuperAdmin ? true : false);
        localStorage.setItem('adminType', data?.token?.adminType);
        localStorage.setItem('modulePermission', JSON.stringify(data?.token?.permissions));

        return {
            type: actions.LOGIN_SUCCESS,
            ...data
        };
    },
    logout: () => {
        localStorage.clear();
        return {
            type: actions.LOGOUT
        };
    },
    permissions: (data) => {
        return {
            type: actions.MODULE_PERMISSION,
            permissions: data
        };
    }
};
export default actions;
