import { Typography } from 'antd';
import React from 'react';
import { siteConfig } from '../../settings';

export default ({ collapsed }) => {
    return (
        <div className="isoLogoWrapper">
            {collapsed ? (
                <div
                    style={{
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                    <img src={siteConfig?.siteIcon} alt="logo" width={40} height={40} />
                </div>
            ) : (
                <Typography
                    style={{
                        color: '#fff',
                        fontSize: '24px',
                        height: '100%',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                    {siteConfig.siteName}
                </Typography>
            )}
        </div>
    );
};
