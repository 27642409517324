const options = [
    {
        key: 'dashboard',
        label: 'sidebar.dashboard',
        leftIcon: 'ion-podium',
        order: 1
    },
    {
        key: 'admin',
        label: 'sidebar.admin',
        leftIcon: 'ion-ios-person',
        order: 2
    },
    {
        key: 'user',
        label: 'sidebar.users',
        leftIcon: 'ion-ios-people',
        order: 3,
        children: [
            {
                key: 'users',
                label: 'sidebar.total-users',
                leftIcon: 'ion-ios-people'
            },
            {
                key: 'logged-in',
                label: 'sidebar.logged-in-users',
                leftIcon: 'ion-android-contacts'
            },
            {
                key: 'filled-questionnaire',
                label: 'sidebar.users-filled-questionnaire',
                leftIcon: 'ion-ios-paper'
            },
            {
                key: 'did-not-filled-questionnaire',
                label: 'sidebar.users-did-not-filled-questionnaire',
                leftIcon: 'ion-ios-paper-outline'
            },
            {
                key: 'referred-users',
                label: 'sidebar.referred-users',
                leftIcon: 'ion-information-circled'
            }
        ]
    },

    {
        key: 'questionnaire',
        label: 'sidebar.questionnaire',
        leftIcon: 'ion-ios-help-outline',
        order: 4,
        children: [
            {
                key: 'questions',
                label: 'sidebar.questions',
                leftIcon: 'ion-ios-list'
            },
            {
                key: 'keywords',
                label: 'sidebar.keywords',
                leftIcon: 'ion-ios-paperplane'
            },
            {
                key: 'interest',
                label: 'sidebar.interest',
                leftIcon: 'ion-android-bulb'
            },
            {
                key: 'tag',
                label: 'sidebar.tag',
                leftIcon: 'ion-android-bookmark'
            },

            {
                key: 'language',
                label: 'sidebar.language',
                leftIcon: 'ion-ios-book'
            },

            {
                key: 'hear-about-us',
                label: 'sidebar.hear-about-us',
                leftIcon: 'ion-android-clipboard'
            }
        ]
    },
    {
        key: 'location',
        label: 'sidebar.location',
        leftIcon: 'ion-ios-location',
        order: 5,
        children: [
            {
                key: 'region',
                label: 'sidebar.region',
                leftIcon: 'ion-ios-world'
            },
            {
                key: 'country',
                label: 'sidebar.country',
                leftIcon: 'ion-earth'
            }
        ]
    },

    {
        key: 'contents',
        leftIcon: 'ion-ios-albums',
        label: 'sidebar.content',
        order: 6,
        children: [
            {
                key: 'content',
                leftIcon: 'ion-ios-albums',
                label: 'sidebar.content'
            },
            {
                key: 'programs',
                label: 'sidebar.calendar',
                leftIcon: 'ion-ios-calendar'
            },
            {
                key: 'category',
                label: 'sidebar.category',
                leftIcon: 'ion-ios-eye'
            }
        ]
    },
    {
        key: 'timer',
        label: 'sidebar.timer',
        leftIcon: 'ion-android-notifications',
        order: 7,
        children: [
            {
                key: 'sound',
                label: 'sidebar.sound',
                leftIcon: 'ion-android-notifications'
            },
            {
                key: 'background-images',
                label: 'sidebar.background-images',
                leftIcon: 'ion-android-image'
            }
        ]
    },

    {
        key: 'subscription',
        label: 'sidebar.subscriptions',
        leftIcon: 'ion-ribbon-b',
        order: 8,
        children: [
            {
                key: 'subscription',
                label: 'sidebar.subscription',
                leftIcon: 'ion-ribbon-b'
            },
            {
                key: 'access-control',
                label: 'sidebar.access-control',
                leftIcon: 'ion-android-lock'
            }
        ]
    },
    {
        key: 'referrals',
        label: 'sidebar.referrals',
        leftIcon: 'ion-android-walk',
        order: 12,
        children: [
            {
                key: 'product-categories',
                label: 'sidebar.product-categories',
                leftIcon: 'ion-social-designernews'
            },
            {
                key: 'referral-products',
                label: 'sidebar.referral-products',
                leftIcon: 'ion-ios-pricetags-outline'
            }
        ]
    },
    {
        key: 'daily-wisdom',
        label: 'sidebar.daily-wisdom',
        leftIcon: 'ion-quote',
        order: 9
    },
    {
        key: 'sales-page',
        label: 'sidebar.sales-page',
        leftIcon: 'ion-arrow-graph-up-right',
        order: 10
    },
    {
        key: 'setting',
        label: 'sidebar.settings',
        leftIcon: 'ion-gear-a',
        order: 11,
        children: [
            {
                key: 'change-password',
                label: 'sidebar.changePassword',
                leftIcon: 'ion-ios-settings'
            },
            {
                key: 'static-pages',
                label: 'sidebar.static-pages',
                leftIcon: 'ion-ios-paper',
                order: 16
            },
            {
                key: 'email',
                label: 'sidebar.email-templates',
                leftIcon: 'ion-email'
            },
            {
                key: 'notifications',
                label: 'sidebar.notifications-template',
                leftIcon: 'ion-android-notifications'
            },
            {
                key: 'feedback',
                label: 'sidebar.feedback',
                leftIcon: 'ion-android-chat',
                order: 17
            },
            {
                key: 'constants',
                label: 'sidebar.constants',
                leftIcon: 'ion-ios-browsers'
            }
        ]
    }
    // {
    //     key: 'sound',
    //     label: 'sidebar.sound',
    //     leftIcon: 'ion-android-notifications',
    //     order: 4
    // },
    // {
    //     key: 'keywords',
    //     label: 'sidebar.keywords',
    //     leftIcon: 'ion-ios-paperplane',
    //     order: 5
    // },

    // {
    //     key: 'category',
    //     label: 'sidebar.category',
    //     leftIcon: 'ion-ios-eye',
    //     order: 7
    // },
    // {
    //     key: 'content-box',
    //     label: 'sidebar.content-box',
    //     leftIcon: 'ion-android-document',
    //     order: 8
    // },
    // {
    //     key: 'content',
    //     label: 'sidebar.content',
    //     leftIcon: 'ion-ios-albums',
    //     order: 8
    // },
    // {
    //     key: 'programs',
    //     label: 'sidebar.calendar',
    //     leftIcon: 'ion-ios-calendar',
    //     order: 9
    // },
    // {
    //     key: 'background-images',
    //     label: 'sidebar.background-images',
    //     leftIcon: 'ion-android-image',
    //     order: 10
    // },
    // {
    //     key: 'interest',
    //     label: 'sidebar.interest',
    //     leftIcon: 'ion-android-bulb',
    //     order: 11
    // },
    // {
    //     key: 'tag',
    //     label: 'sidebar.tag',
    //     leftIcon: 'ion-android-bookmark',
    //     order: 12
    // },
    // {
    //     key: 'subscription',
    //     label: 'sidebar.subscription',
    //     leftIcon: 'ion-ribbon-b',
    //     order: 13
    // },
    // {
    //     key: 'location',
    //     label: 'sidebar.location',
    //     leftIcon: 'ion-ios-location',
    //     order: 14,
    //     children: [
    //         {
    //             key: 'region',
    //             label: 'sidebar.region'
    //         },
    //         {
    //             key: 'country',
    //             label: 'sidebar.country'
    //         }
    //     ]
    // },
    // {
    //     key: 'currency',
    //     label: 'sidebar.currency',
    //     leftIcon: 'ion-cash',
    //     order: 16
    // },
    // {
    //     key: 'feedback',
    //     label: 'sidebar.feedback',
    //     leftIcon: 'ion-android-chat',
    //     order: 16
    // },
    // {
    //     key: 'daily-wisdom',
    //     label: 'sidebar.daily-wisdom',
    //     leftIcon: 'ion-quote',
    //     order: 17
    // },
    // {
    //     key: 'language',
    //     label: 'sidebar.language',
    //     leftIcon: 'ion-ios-book',
    //     order: 18
    // },
    // {
    //     key: 'referred-users',
    //     label: 'sidebar.referred-users',
    //     leftIcon: 'ion-information-circled',
    //     order: 19
    // },
    // {
    //     key: 'template',
    //     label: 'sidebar.template',
    //     leftIcon: 'ion-clipboard',
    //     order: 20,
    //     children: [
    //         {
    //             key: 'notifications',
    //             label: 'sidebar.notifications'
    //         },
    //         {
    //             key: 'email',
    //             label: 'sidebar.email'
    //         }
    //     ]
    // }
    // {
    //     key: 'access-control',
    //     label: 'sidebar.access-control',
    //     leftIcon: 'ion-android-lock',
    //     order: 21
    // }
    // {
    //     key: 'report-reasons',
    //     label: 'sidebar.report-reasons',
    //     leftIcon: 'ion-android-list',
    //     order: 24
    // }
    // {
    //     key: 'location',
    //     label: 'sidebar.location',
    //     leftIcon: 'ion-ios-location',
    //     order: 15,
    //     children: [
    //         {
    //             key: 'region',
    //             label: 'sidebar.region'
    //         },
    //         {
    //             key: 'country',
    //             label: 'sidebar.country'
    //         }
    //     ]
    // },

    // {
    //     key: 'email-templates',
    //     label: 'sidebar.email-templates',
    //     leftIcon: 'ion-paintbrush'
    // }
    // {
    //     key: 'manage-category',
    //     label: 'sidebar.manageCategory',
    //     leftIcon: 'ion-ios-list'
    // },
    // {
    //     key: 'manage-sub-category',
    //     label: 'sidebar.manageSubCategory',
    //     leftIcon: 'ion-ios-list'
    // },
    // {
    //     key: 'settings',
    //     label: 'sidebar.settings',
    //     leftIcon: 'ion ion-wrench'
    // },
    // {
    //     key: 'change-password',
    //     label: 'sidebar.changePassword',
    //     leftIcon: 'ion-ios-settings'
    // }
];
export default options;
