// export default {
//     apiUrl: 'http://yoursite.com/api/'
// };
import HEART_LOGO from 'assets/images/HeartInCircle.png';
import moment from 'moment';

const currentYear = moment().format('YYYY');
const siteConfig = {
    siteName: 'ONENESS HEART',
    siteIcon: HEART_LOGO,
    footerText: `Oneness Heart ©${currentYear}`
};
const themeConfig = {
    topbar: 'themedefault',
    sidebar: 'themedefault',
    layout: 'themedefault',
    theme: 'themedefault'
};
const language = 'english';

const jwtConfig = {
    fetchUrl: '/api/',
    secretKey: 'secretKey'
};

export { siteConfig, language, themeConfig, jwtConfig };
